/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

tr.detail-row {
  height: 0 !important;
}

.element-row td {
  border-bottom-width: 0;
}

.element-detail {
  overflow: hidden;
  display: flex;
}

// Quality
.label-common {
  background-color: #ffffff;
  color: #000000;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

.label-uncommon {
  background-color: #1eff00;
  color: #ffffff;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

.label-rare {
  background-color: #0070dd;
  color: #ffffff;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

.label-epic {
  background-color: #a335ee;
  color: #ffffff;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

.label-legendary {
  background-color: #ff8000;
  color: #ffffff;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

.label-artifact {
  background-color: #e6cc80;
  color: #000000;
  padding: 6px;
  font-weight: bold;
  border-radius: 4px;
}

.font-semibold {
  font-weight: 500 !important;
}

.dd-darker {
  color: rgba(var(--fuse-text-default-rgb), var(--tw-text-opacity)) !important;
}

.mat-column-min150 {
  min-width: 150px;
}

.json-bg {
  border-radius: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #262626;
  color: #ffffff;
}

pre {
  font-weight: 400;

  .number-line {
    color: #adadaf;
  }

  .string {
    color: #95c602;
  }

  .number {
    color: #f2b619;
  }

  .boolean {
    color: #0097f1;
  }

  .null {
    color: #727990;
  }

  .key {
    color: #fff;
  }
}

// Hide AuthenLogs Tab Label in player profiles
.mat-tab-group .mat-tab-label:nth-child(10) {
  display: none;
}

// Hide AuthenLogs Tab Label in player profiles
.mat-tab-group .mat-tab-label:nth-child(11) {
  display: none;
}
